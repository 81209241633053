import React from 'react'
import '../styles/Footer.css'

import location from '../images/location.png'
import whatsapp from '../images/whatsapp.png'
import phone from '../images/phone.png'
import mail from '../images/mail.png'
import logo3 from '../images/logo3.png'
import spBoxLogo from '../images/sp.png'
import { Link } from 'gatsby'

const Footer = () => {
  return (
    <div>
      <div className='Footer bg-darkGrey text-white sm:mt-20 mt-0'>

        <div className='flex justify-center items-center xl:py-9  lg:py-8  md:py-8  sm:py-7 py-10'>
          <div className='grid grid-cols-1 sm:grid-cols-3 sm:gap-12 gap-0 xl:max-w-6xl 2xl:max-w-7xl lg:max-w-5xl md:max-w-3xl mx-auto px-4 sm:px-6'>

            <div className='column1Footer'>
              <div className='flex sm:justify-start justify-center'>
                <Link to="/"><img className='logoFooter' src={logo3} alt='' /></Link>
              </div>
              <div className='flex sm:justify-start justify-center'>
                <Link to="https://speakerbox.in/"><img className='spLogoFooter' src={spBoxLogo} alt='' /></Link>
              </div>
            </div>

            <div className='column2Footer flex items-center justify-end col-span-2 pt-7 sm:pt-0'>
              <div>
                <div className='flex items-start justify-end pb-5'>
                  <Link to='https://www.google.com/maps/place/Ramasko+Packing+System/@21.4964126,39.2114093,17z/data=!3m1!4b1!4m5!3m4!1s0x15c3cf93845872cd:0xe3dd6e34fe3b9168!8m2!3d21.4964076!4d39.213598'><h1 className='addressTextFooter text-right font-Almarai'>جدة - الفيحاء - المطار القديم</h1></Link>
                  <img className='lg:w-4 md:w-3 w-3' src={location} alt='' />
                </div>
                <div className='sm:flex sm:justify-start'>
                  <div className='flex items-start justify-end'>
                    <h1 className='addressTextFooter text-right'> +9660532447438<br/>+966551161149</h1>
                    <img className='lg:w-5 md:w-4 w-4' src={phone} alt='' />
                  </div>
                  <div className=''>
                    <div className='flex items-center justify-end'>
                      <h1 className='addressTextFooter text-right'> +9665324447438</h1>
                      <img className='lg:w-5 md:w-4 w-4' src={whatsapp} alt='' />
                    </div>
                    <div className='flex items-center justify-end'>
                      <h1 className='addressTextFooter text-right'> ramasko33@gmail.com</h1>
                      <img className='lg:w-5 md:w-4 w-4' src={mail} alt='' />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  )
}

export default Footer
